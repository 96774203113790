<template>
  <div class="text-center text-white bg-red">
    <b-row align-h="center" class="wrapper no-gutters py-5">
      <b-col class="col-md-5 mt-5 px-3">
        <h2 class="font-weight-bold mb-4">
          {{ $t("page.successTransaction.paymentSuccessMessage") }}
        </h2>
        <h4 class="font-weight-normal">
          {{ $t("page.successTransaction.paymentDescription") }}
        </h4>
        <b-button
          class="mt-5 text-uppercase title__3"
          variant="light"
          @click="goToProjectPage"
        >
          {{ $t("button.goToProjectsPage") }}
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  methods: {
    goToProjectPage() {
      this.$router.replace({ name: "Projects" });
    }
  }
};
</script>

<style scoped>
.wrapper {
  min-height: calc(100vh - 40px - 16px);
}
</style>
